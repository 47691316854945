<template>
    <div class="p-4">
        <top-search></top-search>

        <div class="w-full flex justify-between my-4 p-4">
            <label for="users" class="font-semibold">Orders</label>
            <div class="flex justify-between">
                <button class="px-5 py-2 rounded-lg hover:outline">
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>

        <div class="w-full p-5">
            <div class="bg-white flex justify-between p-4">
                <div>
                    <input type="text" placeholder="Search" v-model="search" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
                
                <div>
                    <input type="date" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
            </div>
        </div>

        <div v-if="!order_loader" class="w-full my-12 px-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 class="font-semibold text-lg text-left">Orders</h3>
                        </div>
                    </div>
                </div>
                <div class="block w-full h-96 overflow-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Time</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Qty</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Cost</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Rider</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th>
                            </tr>
                        </thead>

                        <tbody v-if="orders">
                            <tr class="bg-white" v-for="(order, index) in orders" :key="order.id">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span> 
                                    <!-- <img class="w-8 h-8 rounded-md object-center place-self-center" loading="lazy" :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/' + order.menu.image"> -->
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <div v-if="order.menu">{{ order.menu.name }}</div>
                                    <div v-if="order.menu">{{ order.menu.description }}</div>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ moment(order.created_at).format('DD, MMM YYYY') }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ moment(order.created_at).format('HH:mm:ss') }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ order.quantity }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase"><span class="line-through">N</span> {{ order.price }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ order.order.rider.user.first_name }} {{ order.order.rider.user.last_name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase"><button class="px-4 py-2 bg-brown-default text-gray-50 rounded-md capitalize">{{ order.order.status }}</button></td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <button class="px-4 py-2 text-brown-default border-brown-default bg-gray-50 rounded-md shadow border-2 focus:outline-none focus:border-brown-default hover:bg-brown-default hover:text-white">View</button>
                                    <!-- <button @click="id = order.id, status = order.status, orderUpdateRequest()">On</button> -->
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'

export default {
    name: 'Order',
    components: {
        TopSearch,
        SquareGrid,
    },
    data() {
        return {
            moment: moment,
            search: '',
        }
    },
    computed: {
        ...mapGetters({
            orders: 'ordersModule/getOrders',
            order_loader: 'ordersModule/getOrderLoader',
        })
    },
    methods: {
        filteredOrers () {
            return this.orders.filter(order => {
                return order.menu.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
            })
        }
    }
}
</script>